import { Menu } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PATH } from 'routes/constants';

function MainMenu() {
  const location = useLocation();
  const [current, setCurrent] = useState('');

  useEffect(() => {
    if (location.pathname === PATH.HOME) {
      setCurrent(PATH.HOME);
    } else {
      const selectedItem = items
        .slice(1)
        .find(
          (item) => location.pathname.indexOf(item?.key?.toString() ?? '') === 0
        );
      setCurrent(selectedItem?.key?.toString() ?? '');
    }
  }, [location.pathname]);

  return (
    <div id="MainMenu">
      <Menu theme="dark" mode="inline" items={items} selectedKeys={[current]} />
    </div>
  );
}

const items: ItemType<MenuItemType>[] = [
  {
    key: PATH.HOME,
    label: <Link to={PATH.HOME}>Dashboard</Link>,
  },
  {
    key: PATH.RESTAURANTS,
    label: <Link to={PATH.RESTAURANTS}>Restaurants</Link>,
  },
  {
    key: PATH.FUNDRAISERS,
    label: <Link to={PATH.FUNDRAISERS}>Fundraisers</Link>,
  },
  // {
  //   key: PATH_NAME.USERS,
  //   label: <Link to={PATH_NAME.USERS}>Users</Link>,
  // },
];

export default memo(MainMenu);
