import { Spin } from 'antd';
import { Suspense, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from 'routes';
import './App.css';
import ErrorBoundary from 'pages/500';
import { useGetMe } from 'hooks/users/useGetMe';
import { LOCAL_STORAGE_KEY } from 'utils/constants';

function App() {
  const router = createBrowserRouter(routes);
  const me = useGetMe();

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)) {
      me.refetch();
    }

    const listener = (e: StorageEvent) => {
      if (e.key === LOCAL_STORAGE_KEY.ACCESS_TOKEN && e.newValue) {
        me.refetch();
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Spin size="large" />}>
        {me.isLoading ? (
          <Spin tip="Loading user...">
            <div style={{ height: '100vh' }} />
          </Spin>
        ) : (
          <RouterProvider router={router} />
        )}
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
