export enum PATH {
  ALL = '*',
  HOME = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  PROFILE = '/profile',
  FUNDRAISERS = '/fundraisers',
  RESTAURANTS = '/restaurants',
  USERS = '/users',
}
