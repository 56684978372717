import { notification } from 'antd';
import { usersAPI } from 'api/users';
import { useQuery, useQueryClient } from 'react-query';
import { PATH } from 'routes/constants';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import { QUERY_KEY, USER_ROLE } from 'utils/enums';

type PropsType = {
  enabled?: boolean;
};

export function useGetMe(props?: PropsType) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: QUERY_KEY.GET_ME,
    queryFn: usersAPI.getMe,
    cacheTime: Infinity,
    refetchOnMount: false,
    enabled: props?.enabled ?? false,
    onSuccess: (data) => {
      if (data && data.userRole !== USER_ROLE.ADMIN) {
        notification.error({ message: 'Unauthorized' });
        clearToken();
      }
    },
    onError: () => {
      clearToken();
    },
  });

  function clearToken() {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
    queryClient.setQueryData(QUERY_KEY.GET_ME, null);
    window.location.href = PATH.LOGIN;
  }

  return query;
}
