import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Flex, Layout, Space, Spin } from 'antd';
import Logo from 'components/common/Logo';
import MainMenu from 'components/common/MainMenu';
import UserMenu from 'components/users/UserMenu';
import { Suspense, memo, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { PATH } from 'routes/constants';
import { LOCAL_STORAGE_KEY } from 'utils/constants';
import './MainLayout.css';

function MainLayout() {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.key === LOCAL_STORAGE_KEY.ACCESS_TOKEN && !e.newValue) {
        localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
        navigate(PATH.LOGIN, { replace: true });
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  if (!localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)) {
    return <Navigate to={PATH.LOGIN} replace />;
  }

  return (
    <Layout id="MainLayout">
      <Layout.Sider
        className="MainLayout_Sider"
        trigger={null}
        collapsed={collapsed}
        collapsedWidth={0}
      >
        <Logo className="MainLayout_Logo" hideTitle={collapsed} />
        <MainMenu />
      </Layout.Sider>
      <Layout>
        <Layout.Header className="MainLayout_Header">
          <Flex justify="space-between" align="center">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <Space>
              {/* <UserNotification /> */}
              <UserMenu />
            </Space>
          </Flex>
        </Layout.Header>
        <Layout.Content className="MainLayout_Content">
          <div className="MainLayout_Content_Container">
            <Suspense
              fallback={
                <Spin tip="Loading...">
                  <div style={{ height: 200 }} />
                </Spin>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default memo(MainLayout);
