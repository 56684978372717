import { Image, Space, Typography } from 'antd';
import './Logo.css';

type PropsType = {
  className?: string;
  style?: React.CSSProperties | undefined;
  hideTitle?: boolean;
};

function Logo(props: PropsType) {
  return (
    <div id="Logo" className={props.className} style={props.style}>
      <Space size="middle" className="Logo_Container">
        <Image src="/logo.svg" width={40} height={40} preview={false} />
        {!props.hideTitle && (
          <Typography.Title className="Logo_Title" level={1}>
            Fundify
          </Typography.Title>
        )}
      </Space>
    </div>
  );
}

export default Logo;
