import { Avatar, AvatarProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';

function UserAvatar(props: AvatarProps) {
  return (
    <div id="UserAvatar">
      <Avatar {...props} icon={props.icon ?? <UserOutlined />} />
    </div>
  );
}

export default UserAvatar;
