import { Dropdown, MenuProps, Space, Typography } from 'antd';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetMe } from 'hooks/users/useGetMe';
import { useLogout } from 'hooks/auth/useLogout';
import { PATH } from 'routes/constants';
import './UserMenu.css';
import UserAvatar from './UserAvatar';

function UserMenu() {
  const navigate = useNavigate();
  const me = useGetMe();
  const logout = useLogout();

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: 'Profile',
      onClick: () => navigate(PATH.PROFILE),
    },
    { type: 'divider' },
    {
      key: 'logout',
      label: 'Logout',
      danger: true,
      onClick: () => logout.mutate(),
    },
  ];

  return (
    <div id="UserMenu">
      <Dropdown menu={{ items }} trigger={['hover']}>
        <Space>
          <UserAvatar src={me.data?.avatar} size="small" />
          <Typography.Text strong>{me.data?.fullName}</Typography.Text>
        </Space>
      </Dropdown>
    </div>
  );
}

export default memo(UserMenu);
