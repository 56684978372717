import { Flex, Layout, Spin } from 'antd';
import Logo from 'components/common/Logo';
import { useGetMe } from 'hooks/users/useGetMe';
import { ReactNode, Suspense, memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { PATH } from 'routes/constants';
import './AuthLayout.css';

type Props = {
  children?: ReactNode;
};

function AuthLayout(props: Props) {
  const me = useGetMe();

  if (me.data?.id) {
    return <Navigate to={PATH.HOME} replace />;
  }

  return (
    <Layout id="AuthLayout">
      <Layout.Content className="AuthLayout_Content">
        <Flex className="AuthLayout_Content_Container" vertical>
          <Logo className="AuthLayout_Logo" />
          <Suspense fallback={<Spin size="large" />}>
            {props.children ?? <Outlet />}
          </Suspense>
        </Flex>
      </Layout.Content>
    </Layout>
  );
}

export default memo(AuthLayout);
