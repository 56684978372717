import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PATH } from './constants';
import AuthLayout from 'components/layouts/AuthLayout';
import MainLayout from 'components/layouts/MainLayout';

const NotFoundPage = lazy(() => import('pages/404'));
const HomePage = lazy(() => import('pages/Home'));
const LoginPage = lazy(() => import('pages/Login'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'));
const ProfilePage = lazy(() => import('pages/Profile'));
const RestaurantsPage = lazy(() => import('pages/Restaurants'));
const FundraisersPage = lazy(() => import('pages/Fundraisers'));

export const routes: RouteObject[] = [
  {
    Component: MainLayout,
    children: [
      {
        path: PATH.ALL,
        Component: NotFoundPage,
      },
      {
        path: PATH.HOME,
        Component: HomePage,
      },
      {
        path: PATH.PROFILE,
        Component: ProfilePage,
      },
      {
        path: PATH.RESTAURANTS,
        Component: RestaurantsPage,
      },
      {
        path: PATH.FUNDRAISERS,
        Component: FundraisersPage,
      },
    ],
  },
  {
    Component: AuthLayout,
    children: [
      {
        path: PATH.LOGIN,
        Component: LoginPage,
      },
      {
        path: PATH.FORGOT_PASSWORD,
        Component: ForgotPasswordPage,
      },
      {
        path: PATH.RESET_PASSWORD,
        Component: ResetPasswordPage,
      },
    ],
  },
];
