import axiosClient, { AxiosRequestConfig } from 'utils/axios-client';
import { LoginDataDto, LoginDto } from 'dto/auth/login.dto';
import { SendOtpDto } from 'dto/auth/send-otp.dto';
import { DefaultResponseDto } from 'dto/common/default-response.dto';
import { VerifyOtpDto, VerifyOtpDataDto } from 'dto/auth/verify-otp.dto';
import { ResetPasswordDto } from 'dto/auth/reset-password.dto';
import { TokenDto } from 'dto/auth/token.dto';
import { LogoutDto } from 'dto/auth/logout.dto';

export const authAPI = {
  login(payload: LoginDto): Promise<LoginDataDto> {
    return axiosClient.post('auth/login', payload);
  },

  logout(payload: LogoutDto): Promise<DefaultResponseDto> {
    return axiosClient.post('auth/logout', payload, {
      _ignoreNotificationError: true,
    } as AxiosRequestConfig);
  },

  sendOtpResetPassword(payload: SendOtpDto): Promise<DefaultResponseDto> {
    return axiosClient.post('auth/reset-password/send-otp', payload);
  },

  verifyOtpResetPassword(payload: VerifyOtpDto): Promise<VerifyOtpDataDto> {
    return axiosClient.post('auth/reset-password/verify-otp', payload);
  },

  resetPassword(
    payload: ResetPasswordDto,
    token: string
  ): Promise<DefaultResponseDto> {
    return axiosClient.post('auth/reset-password', payload, {
      headers: { Authorization: `Bearer ${token}` },
      _ignoreRefresh: true,
    } as AxiosRequestConfig);
  },

  refreshToken(token: string): Promise<TokenDto> {
    return axiosClient.post('auth/refresh-token', {}, {
      headers: { Authorization: `Bearer ${token}` },
      _ignoreRefresh: true,
    } as AxiosRequestConfig);
  },
};
